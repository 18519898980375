import React, { useState } from "react"
import Image from "gatsby-image"

const Video = ({ fluid, url }) => {
  const [showvideo, setshowvideo] = useState(false)
  const Handler = () => {
    setshowvideo(true)
  }
  if (showvideo) {
    return (
      <video
        style={{ width: "100%", height: "100%" }}
        src={url}
        loop
        muted
        autoPlay
      />
    )
  } else {
    return (
      <div onLoad={Handler}>
        <Image fluid={fluid} />;
      </div>
    )
  }
}

export default Video
