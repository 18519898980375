import React from "react"
import { graphql, StaticQuery } from "gatsby"
import Image from "gatsby-image"
import Video from "./Video.js"

const Instagram = () => (
  <StaticQuery
    query={graphql`
      query InstagramPosts {
        allInstaNode(limit: 8, sort: { fields: timestamp, order: DESC }) {
          edges {
            node {
              id
              mediaType
              original
              localFile {
                publicURL
                childImageSharp {
                  fluid(maxWidth: 400, maxHeight: 500, quality: 90) {
                    ...GatsbyImageSharpFluid_noBase64
                  }
                }
              }
              localVideo{
                publicURL
              }
            }
          }
        }
      }
    `}
    render={(data) => (
      <div className="instanodes">
        {data.allInstaNode.edges.map((item, i) =>
          item.node.localFile ? (
            <div key={i}>
              <a
                href={"https://instagram.com/p/" + item.node.id}
                rel="noopener noreferrer"
                target="_blank"
                tabIndex="0"
              >
                {item.node.mediaType !== "VIDEO" ? (
                  <Image fluid={item.node.localFile.childImageSharp.fluid} />
                ) : (
                  <div className="videocontain">
                    <Video
                      fluid={item.node.localFile.childImageSharp.fluid}
                      url={item.node.localVideo.publicURL}
                    />
                  </div>
                )}
              </a>
            </div>
          ) : (
            <div></div>
          )
        )}
      </div>
    )}
  />
)

export default Instagram
