import React, { useState, useContext, useEffect } from "react"
import { TrackContext } from "../TrackProvider"
import "./splashstyles.scss"

// TODO force imminent bg-img load
// -> hard code styles into main.html
//

const SplashWaitMins = 168 * 60 // ~ a weeks in minutes
const MinsInMiliSec = 60000

const SplashScreen = () => {
  /* Animation settings and flags */
  const [preanimclass, setPreanimclass] = useState("pre-anim-state")
  const [fadeoutclass, setfadeoutclass] = useState("")
  const pauseTime = 1800
  const waitBeforeRemove = 600

  /* Timing and State */
  const { setStore, getStore } = useContext(TrackContext)
  const [done, setDone] = useState(() => {
    // return false
    const storedNextPlay = getStore("SplashScreenNextPlay", null)
    if (storedNextPlay === null) {
      // First play
      //console.log("First Play Intro")
      const nextSchedule = new Date(Date.now() + MinsInMiliSec * SplashWaitMins)
      setStore("SplashScreenNextPlay", nextSchedule)
      return false
    }
    const curSchedule = new Date(Date.parse(storedNextPlay))

    if (Date.now() >= Date.parse(curSchedule)) {
      // Subsequent play
      //console.log("Play intro")
      const nextSchedule = new Date(Date.now() + MinsInMiliSec * SplashWaitMins)
      setStore("SplashScreenNextPlay", nextSchedule)
      return false
    } else {
      // No play - deadline not reached.
      //console.log("No intro")
      return true
    }
  })

  /* Animation timing & actions  */
  useEffect(() => {
    if (done) {
      setfadeoutclass("extraHide")
      return
    }
    setTimeout(() => {
      setPreanimclass("") // first timer to avoid race condition with state storage.
      setTimeout(() => {
        setfadeoutclass("fadeout")
        setTimeout(() => {
          setDone(true)
        }, waitBeforeRemove)
      }, pauseTime)
    }, 50)
  }, [])

  if (done) {
    return <div className={fadeoutclass} />
  }

  return (
    <div className={done ? "extraHide" : "introModal " + fadeoutclass}>
      <div className="imBannerwrap">
        <div className={"introBanner " + preanimclass}>
          <div className="imbanimg">&nbsp;</div>
        </div>
      </div>
    </div>
  )
}

export default SplashScreen
