import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

const BookletImage = data => {
  const { allFile } = useStaticQuery(graphql`
    query {
      allFile(filter: { relativeDirectory: { in: "BookletImage" } }) {
        edges {
          node {
            name
            relativeDirectory
            childImageSharp {
              fluid(quality: 90) {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
        }
      }
    }
  `)

  const images = allFile.edges.map(edge => edge.node.childImageSharp.fluid)

  return <Img className="bookletimage" fluid={images[0]} />
}

export default BookletImage
