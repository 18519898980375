import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
} from "pure-react-carousel"
import "pure-react-carousel/dist/react-carousel.es.css"
import Img from "gatsby-image"

const DeathSlider = () => {
  const { allFile } = useStaticQuery(graphql`
    query {
      allFile(filter: { relativeDirectory: { in: "UntilDeath" } }) {
        edges {
          node {
            name
            relativeDirectory
            childImageSharp {
              fluid(quality: 90, maxWidth: 1600) {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
        }
      }
    }
  `)

  const images = allFile.edges
    .sort((a, b) => {
      return a.node.name.localeCompare(b.node.name)
    })
    .map(edge => edge.node.childImageSharp.fluid)

  return (
    <CarouselProvider
      naturalSlideWidth={100}
      naturalSlideHeight={50}
      totalSlides={3}
      visibleSlides={1}
      infinite
      isIntrinsicHeight
    >
      <Slider className="deathsliderslides">
        <Slide index={0}>
          <div className="slide3">
            <Img className="slide3" fluid={images[0]} />
          </div>
        </Slide>
        <Slide index={1}>
          <div className="slide3">
            <Img className="slide3" fluid={images[1]} />
          </div>
        </Slide>
        <Slide index={2}>
          <div className="slide3">
            <Img className="slide3" fluid={images[2]} />
          </div>
        </Slide>
      </Slider>
      <ButtonBack className="deatharrowback">&#8249;</ButtonBack>
      <ButtonNext className="deatharrownext">&#8250;</ButtonNext>
    </CarouselProvider>
  )
}

export default DeathSlider
