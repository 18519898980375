import React, { useEffect, useState } from "react"
import { graphql, useStaticQuery, Link } from "gatsby"
import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
} from "pure-react-carousel"
import "pure-react-carousel/dist/react-carousel.es.css"
import Img from "gatsby-image"
import { useMediaQuery } from "react-responsive"

const ShopSlider = ({ data }) => {
  const { allFile } = useStaticQuery(graphql`
    query {
      allFile(filter: { relativeDirectory: { in: "ShopSlider" } }) {
        edges {
          node {
            name
            relativeDirectory
            childImageSharp {
              fluid(quality: 90) {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
        }
      }
    }
  `)

  const images = allFile.edges
    .sort((a, b) => {
      return a.node.name.localeCompare(b.node.name)
    })
    .map(edge => edge.node.childImageSharp.fluid)

  const isDesktop = useMediaQuery({ query: "(min-device-width: 1024px)" })
  const isTablet = useMediaQuery({ query: "(min-device-width: 600px)" })
  const slideCount = isDesktop ? 3 : isTablet ? 2 : 1

  // slideCount depends on the min-device-width, and will default to 1 when
  // the static site is rendered. Therefore, this component must always be
  // re-rendered in the browser (rather than merely hydrated). Changing the
  // `key` property of a React component forces it to be re-rendered. The
  // `useEffect` hook is executed only on the client side, and changes the
  // `key` property to the new value of the `isClient` state variable.
  const [isClient, setIsClient] = useState(false)
  useEffect(() => {
    setIsClient(true)
  }, [])

  return (
    <CarouselProvider
      naturalSlideWidth={100}
      naturalSlideHeight={120}
      totalSlides={6}
      visibleSlides={slideCount}
      key={isClient}
      infinite
    >
      <Slider className="shopsliderwrap">
        <Slide index={0} className="shopslide">
          <div className="slide2">
            <Img className="slide1" fluid={images[0]} />
          </div>
          <Link className="slidelink" to="/collection/rings">
            <button className="slidetitle">Rings</button>
          </Link>
        </Slide>
        <Slide index={1} className="shopslide">
          <div className="slide2">
            <Img className="slide1" loading="eager" fluid={images[1]} />
          </div>
          <Link className="slidelink" to="/collection/decollete">
            <button className="slidetitle">Décolleté</button>
          </Link>
        </Slide>
        <Slide index={2} className="shopslide">
          <div className="slide2">
            <Img className="slide1" loading="eager" fluid={images[2]} />
          </div>
          <Link className="slidelink" to="/collection/earrings">
            <button className="slidetitle">Earrings</button>
          </Link>
        </Slide>
        <Slide index={3} className="shopslide">
          <div className="slide2">
            <Img className="slide1" loading="eager" fluid={images[3]} />
          </div>
          <Link className="slidelink" to="/collection/homme">
            <button className="slidetitle">Homme</button>
          </Link>
        </Slide>
        <Slide index={4} className="shopslide">
          <div className="slide2">
            <Img className="slide1" loading="eager" fluid={images[4]} />
          </div>
          <Link className="slidelink" to="/collection/bespoke">
            <button className="slidetitle">Bespoke</button>
          </Link>
        </Slide>
        <Slide index={5} className="shopslide">
          <div className="slide2">
            <Img className="slide1" loading="eager" fluid={images[5]} />
          </div>
          <Link className="slidelink" to="/collection/bracelets">
            <button className="slidetitle">Bracelets</button>
          </Link>
        </Slide>
      </Slider>
      <ButtonBack className="arrowback">&#8249;</ButtonBack>
      <ButtonNext className="arrownext">&#8250;</ButtonNext>
    </CarouselProvider>
  )
}

export default ShopSlider
