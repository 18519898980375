import React, { useState } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Link, graphql } from "gatsby"
import Instagram from "../components/Instagram"
import HeaderImage from "../components/headerimage"
import SplashScreen from "../components/SplashScreen/SplashScreen"
import Popup from "reactjs-popup"
import Booklet from "../pages/Dianne Rowe Private Collection Your Bespoke Journey.pdf"
import ShopSlider from "../components/ShopSlider"
import DeathSlider from "../components/DeathSlider"
import { Fade } from "react-awesome-reveal"
import BookletImage from "../components/bookletimage"

const IndexPage = ({ data }) => {
  const headerImageSharp = data.imageSharp
  const subscribeFormHandler = (e) => {
    e.preventDefault()
    var formData = new FormData(e.target)
    fetch(e.target.action, {
      method: "POST",
      mode: "no-cors",
      body: formData,
    })
  }

  return (
    <Layout classExtra="smallfooter">
      <SplashScreen />
      <SEO
        title="Home"
        keywords={[
          `australian`,
          `exclusive`,
          `jewellery`,
          `engagement`,
          `wedding`,
        ]}
        description={`Custom engagement rings and jewellery designs. Made with gold and conflict free, ethical precious stones (morganite, moissanite, sapphire, lab diamond, opal)`}
      />
      <HeaderImage
        imageSharp={headerImageSharp}
        targetClass={"home_slider_background"}
      />
      <div className="home">
        <div className="fontpage-special">
          <div className="home_slider_background"></div>
          <div className="floatinglogo"></div>
        </div>
      </div>
      <div className="indexwrap">
        <Fade className="fadefix">
          <div className="intro">
            <div className="containerfluid">
              <img
                className="processimage"
                src={require("../images/Icons/IconExpress.png")}
              />
              <div className="processtext">
                Free EXPRESS shipping Australia wide with every bespoke or
                Private Collection purchase
              </div>
            </div>
            <div className="containerfluid">
              <img
                className="processimage"
                src={require("../images/Icons/IconBespoke.png")}
              />
              <div className="processtext">
                Ready to create the design of your dreams?
                <br />
                Bring it to life with our unique, obligation-free bespoke design
                process
              </div>
            </div>
            <div className="containerfluid">
              <img
                className="processimage"
                src={require("../images/Icons/IconInternational.png")}
              />
              <div className="processtext">
                Free international shipping
                <br />
                *subject to import law, duties and taxes
              </div>
            </div>
            <div className="containerfluid">
              <img
                className="processimage"
                src={require("../images/Icons/IconEthical.png")}
              />
              <div className="processtext">
                At Dianne Rowe Private Collection we create the jewellery of
                your dreams using ethically sourced mining & conflict free
                gemstones. Enjoy luxury without the guilt.
              </div>
            </div>
          </div>
        </Fade>

        <Fade className="fadefix">
          <div className="indexshopall">
            <div className="shopalltitle">
              SHOP DIANNE ROWE PRIVATE COLLECTION
            </div>
            <div className="shopalltext">
              Shop your next statement piece from Dianne Rowe's exclusive
              Private Collection. Each masterpiece has been delicately designed
              in Western Australia's breathtaking Swan Valley.Peruse confidently
              knowing that this collection has been shared with love in
              celebration of you and those moments, memories and milestones that
              make you feel complete.{" "}
            </div>
            <div className="shopline">
              <Link to="/collection">
                <button className="shopallbutton">SHOP ALL</button>
              </Link>
            </div>
          </div>
        </Fade>

        <Fade className="fadefix">
          <div className="indexshopbytype">
            <div className="shopbytypetitle">SHOP BY TYPE</div>
            <div className="shopslider">
              <ShopSlider />
            </div>
          </div>
        </Fade>

        <Fade className="fadefix">
          <div className="emailsignup">
            <div className="signuptitle">SIGN UP TO OUR EMAIL LIST</div>
            <div className="signuptext">
              We know your inbox is sacred and promise to keep it that way.{" "}
              <br /> Follow for exclusive collection launch details and unique
              Rowe Collection insights.
            </div>
            <div className="emailsubscribeform">
              <form
                onSubmit={subscribeFormHandler}
                id="sender-subscribe"
                action="https://app.sender.net/forms/sender_subscription/8424/6fa66861"
                method="POST"
              >
                <div className="inputformwrap">
                  <div id="subforms">
                    <input
                      name="email"
                      className="inputformindex"
                      id="name"
                      data-label="Name"
                      placeholder="   your name"
                      required
                    />
                  </div>
                  <div className="inputformwrap">
                    <input
                      name="email"
                      className="inputformindex"
                      id="email"
                      data-label="Email"
                      placeholder="   email address"
                      required
                    />
                  </div>
                  <div className="emailbuttonwrap">
                    <button type="submit" className="emailsubmitbutton">
                      SUBSCRIBE
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </Fade>

        <Fade className="fadefix">
          <div className="untildeath">
            <div className="deathimage">
              <div className="combiner">
                <div className="until">Until</div>
                <div className="death">DEATH</div>
              </div>
              <div className="launching">
                ADORN YOURSELF.
                <br />
                DELICATE DECOLLETE, STACKER RINGS & EARRINGS
              </div>
            </div>
            <div className="deathslider">
              <DeathSlider />
            </div>
          </div>
        </Fade>
        <div className="jump" id="bespoke" />
        <Fade className="fadefix">
          <div className="bespokewrap">
            <div className="bespoketitle">begin your bespoke journey</div>
            <div className="indexbespoke">
              <div className="bespoketext">
                Unsure where to start? We're here to help. Download your FREE
                bespoke design guide below. Questions? We'd love to hear from
                you! Let us know what's on your mind at the{" "}
                <Link to="/contact">contact</Link> page.
              </div>
              <div className="bespokeimage">
                <BookletImage />
              </div>
              <div className="downloadwrap">
                <div className="downloadbar" />
                <Popup
                  modal
                  className="Bookpopup"
                  closeOnEscape
                  trigger={
                    <button className="downloadbutton">
                      <a className="downloadtext" href="#bespoke">
                        Download
                      </a>
                    </button>
                  }
                  position="center center"
                >
                  {(close) => (
                    <>
                      {" "}
                      <div className="subclose2">
                        <button
                          className="closebutton"
                          onClick={() => {
                            close()
                          }}
                        >
                          <i className="fa fa-close"></i>
                        </button>
                      </div>
                      <div className="subscribepopup">
                        <div className="popupborder">
                          <div className="emailsubscribeform">
                            <div className="susbscribepopuptitle">
                              Free bespoke guide download
                            </div>
                            <div className="susbscribepopuptext">
                              Input your name and email address below for
                              instant ebook download! <br />
                              We understand your inbox is sacred and plan to
                              keep it that way.
                            </div>
                            <form
                              onSubmit={subscribeFormHandler}
                              id="sender-subscribe"
                              action="https://app.sender.net/forms/sender_subscription/8424/6fa66861"
                              method="POST"
                            >
                              <div className="inputformwrap">
                                <div id="subforms">
                                  <input
                                    name="email"
                                    className="inputformindex2"
                                    id="name"
                                    data-label="Name"
                                    placeholder="   your name"
                                    required
                                  />
                                </div>
                                <div className="inputformwrap">
                                  <input
                                    name="email"
                                    className="inputformindex2"
                                    id="email"
                                    data-label="Email"
                                    placeholder="   email address"
                                    required
                                  />
                                </div>
                                <div className="emailbuttonwrap">
                                  <button
                                    type="submit"
                                    className="emailsubmitbutton"
                                  >
                                    <a
                                      href={Booklet}
                                      className="downloadtext"
                                      download
                                    >
                                      Download
                                    </a>
                                  </button>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </Popup>
                {/* <button download className="downloadbutton">
                  <a href={Booklet} className="downloadtext" download>
                    Download
                  </a>
                </button> */}
                <div className="downloadbuttonblanker" />
              </div>
              <div className="bespokefinaltext">BRING YOUR DREAMS TO LIFE</div>
            </div>
          </div>
        </Fade>

        {/* <div className="extra clearfix">
        <div className="extra_promo extra_promo_1">
          <div className="extra_promo_image promoimage1"></div>
          <div className="extra_2_top text-center ">
            <br />
            <br />
            <br /> WELCOME TO YOUR BESPOKE JOURNEY{" "}
          </div>
          <div className="extra_3_text text-center"> ____ </div>
          <div className="extra_2_text text-center">
            <br />
            The whole universe is at your fingertips when you embark on the
            journey of meticulously designed and flawlessly handcrafted bespoke
            jewellery. No two pieces are the same, just as you uniquely exist
            within the universe.
          </div>
          <br />
          <br />
          <div className="extra_2_text text-center">
            I know how hard it can sometimes be to articulate what is in your
            heart and mind, so here is a small gift to you from me.{" "}
            <a href={Booklet} download className="Bookletlink">
              <u>Within this booklet</u>
            </a>
            you will find prompts and guidance to help you begin your creative
            design journey. If you'd like to talk about any aspects of your
            design, please contact me.
          </div>
          <div className="sigwrap2">
            <div className="aboutsig"></div>
          </div>
        </div>
        <div className="extra_promo extra_promo_2">
          <Popup
            modal
            className="Bookpopup"
            closeOnEscape
            trigger={<div className="extra_promo_image promoimage3" />}
            position="center center"
          >
            {close => (
              <>
                {" "}
                <div className="subclose2">
                  <button
                    className="closebutton"
                    onClick={() => {
                      close()
                    }}
                  >
                    <i className="fa fa-close"></i>
                  </button>
                </div>
                <BookletModal close={close} />{" "}
              </>
            )}
          </Popup>
        </div>
      </div> */}

        <div className="promo">
          {/* <div className="container">
          <div className="row">
            <div className="col"></div>
          </div>
        </div>
        <div className="row promo_container">
          <div className="col-lg-3 promo_col">
            <div className="promo_item">
              <Link to="/collection/rings">
                <div className="promo_image">
                  <img src={Rings} alt="" />
                  <div className="promo_content promo_content_1"></div>
                </div>
              </Link>
              <div className="promo_link">
                <Link to="/collection/rings">Rings | Collection</Link>
              </div>
            </div>
          </div>

          <div className="col-lg-3 promo_col">
            <div className="promo_item">
              <Link to="/collection/pendants">
                <div className="promo_image">
                  <img src={Pendants} alt="" />
                </div>
              </Link>
              <div className="promo_link">
                <Link to="/collection/pendants">Pendants | Collection</Link>
              </div>
            </div>
          </div>

          <div className="col-lg-3 promo_col">
            <div className="promo_item">
              <Link to="/collection/earrings">
                <div className="promo_image">
                  <img src={Earrings} alt="" />
                </div>
              </Link>
              <div className="promo_link">
                <Link to="/collection/earrings">Earrings | Collection</Link>
              </div>
            </div>
          </div>

          <div className="col-lg-3 promo_col">
            <div className="promo_item">
              <Link to="/collection/bespoke">
                <div className="promo_image">
                  <img src={Bespoke} alt="" />
                </div>
              </Link>
              <div className="promo_link">
                <Link to="/collection/bespoke">Bespoke | Collection</Link>
              </div>
            </div>
          </div>
        </div> */}

          <div className="instarow2">
            <div className="instatext">Latest from Instagram</div>

            <Instagram />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default IndexPage

export const MyQuery = graphql`
  {
    imageSharp(fluid: { originalName: { eq: "Homeheader.jpg" } }) {
      id
      fluid {
        srcSet
      }
    }
  }
`
